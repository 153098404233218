<template>
  <el-dialog
    top="5vh"
    width="32rem"
    :visible="true"
    :show-close="false"
    custom-class="them-dialog"
    v-loading="$store.state.requestLoading"
  >
    <div slot="title">添加角色</div>
    <el-form :model="formData" ref="formData" label-width="6rem" :rules="rules">
      <el-form-item label="角色名称:" prop="name">
        <el-input
          clearable
          size="medium"
          style="width: 16rem"
          v-model="formData.name"
          placeholder="请输入角色名称"
        ></el-input>
      </el-form-item>

      <el-form-item label="备注:" prop="memo">
        <el-input type="textarea" rows="5" v-model="formData.memo"></el-input>
      </el-form-item>

      <el-form-item label="权限:" prop="tenantResourceIds">
        <el-tree
          ref="tree"
          show-checkbox
          default-expand-all
          :data="resourceList"
          :props="defaultProps"
          node-key="tenantResourceId"
          :check-on-click-node="true"
          :expand-on-click-node="false"
        >
        </el-tree>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('close')">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    editData: {
      default: null,
      type: Object
    }
  },

  data () {
    return {
      // 权限列表
      resourceList: [],

      // 表单数据
      formData: {
        name: '',
        memo: '',
        roleId: null,
        tenantResourceIds: []
      },

      // 校验规则
      rules: {
        name: [
          {
            required: true,
            message: '请输入角色名称',
            trigger: ['blur', 'change']
          }
        ],
        memo: [
          {
            validator: this.$util.validateFeildLength(100),
            trigger: ['blur', 'change']
          }
        ],
        tenantResourceIds: [
          { required: true, message: '请选择角色权限', trigger: 'blur' }
        ]
      },

      // 默认属性
      defaultProps: {
        children: 'children',
        label: 'name'
      }
    }
  },

  created () {
    // 获取菜单列表
    this.getResourceList(this.setDefaultCheckedKeys)
  },

  methods: {
    setDefaultCheckedKeys () {
      if (!this.editData) {
        return
      }

      // 数据回显
      this.formData = {
        name: this.editData.name,
        memo: this.editData.memo,
        roleId: this.editData.roleId,
        tenantResourceIds: this.editData.resources
      }

      // 获取勾选的权限的key列表
      const checkedKeys = this.formData.tenantResourceIds.map(
        (item) => item.tenantResourceId
      )

      // 根据key列表更新节点选中状态
      checkedKeys && checkedKeys.length && this.$nextTick(() => {
        checkedKeys.forEach(key => {
          const node = this.$refs.tree.getNode(key)
          node.isLeaf && this.$refs.tree.setChecked(node, true)
        })
      })
    },

    // 获取菜单列表
    async getResourceList (callback) {
      const res = await this.$api.getResourceList()
      if (res !== 'fail&&error') {
        this.resourceList = this._assemblyData(res)

        callback && callback()
      }
    },

    // 组装数据格式
    _assemblyData (data) {
      const result = []
      data.forEach((item) => {
        result.push({
          name: item.folderCodeName,
          children: item.list
        })
      })
      return result
    },
    getTenantResourceIds () {
      const checkedKeys = this.$refs.tree.getCheckedKeys()

      const halfCheckedKeys = this.$refs.tree.getHalfCheckedKeys()

      // 过滤掉为null||undefined的数据
      return [...checkedKeys, ...halfCheckedKeys].filter((item) => item)
    },
    submit () {
      // 设置勾选的权限
      this.formData.tenantResourceIds = this.getTenantResourceIds()

      //  1.校验数据
      this.$refs.formData.validate(async (valid) => {
        if (valid) {
          // 2.发送请求
          let res = null
          if (this.editData) {
            res = await this.$api.updateRole(this.formData)
          } else {
            res = await this.$api.createRole(this.formData)
          }
          if (res !== 'fail&&error') {
            this.$emit('close', true)
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>

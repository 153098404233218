<template>
  <div class="container-page role-page">
      <!-- 搜索区域 -->
    <div class="search-box" style="background: #F9F9F9;">
      <el-form inline ref="searchForm" :model="searchForm" @submit.native.prevent>
        <el-form-item label="角色名称" style="width: 13.2rem;">
          <el-input
            clearable
            size="small"
            placeholder="全部"
            style="width: 8.8rem;"
            v-model="searchForm.name"
            @keyup.enter.native='getRoleList'
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="getRoleList"
            >查询</el-button
          >
          <el-button
            type="primary"
            size="small"
            icon="el-icon-plus"
            @click="dialogName = 'add'"
            >添加</el-button
          >
          <el-button type="danger" size="small" @click="handleDelete(roleIds)">批量删除</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 表格列表区域 -->
    <el-table
      ref="tableData"
      :data="tableData"
      :row-style="{ background: '#f9f9f9' }"
      v-loading="$store.state.requestLoading"
      @selection-change="handleSelectionChange"
      :header-cell-style="{ background: '#f9f9f9' }"
      style="border-left: .5px solid #EBEEF5;border-right: .5px solid #EBEEF5;"
    >
      <el-table-column align="center" type="selection" width="55">
      </el-table-column>
      <el-table-column prop="name" label="角色名称" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="memo" label="备注" show-overflow-tooltip> </el-table-column>
      <el-table-column label="创建时间" >
        <template slot-scope="scope">
          {{ $util.dateFormat(scope.row.createTime) }}
        </template>
      </el-table-column>

      <el-table-column align="center" >
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row.roleId)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete([scope.row.roleId])">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pager">
      <el-pagination
        background
        layout="total, prev, pager, next"
        :page-size="pager.pageSize"
        :total="pager.total"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>

    <addDialog v-if="dialogName === 'add'" @close="hideDialog" :editData='editData'></addDialog>
  </div>
</template>
<script>
import addDialog from './addDialog.vue'

export default {
  components: {
    addDialog
  },
  data () {
    return {
      searchForm: { name: '' },
      tableData: [],
      dialogName: '',
      pager: {
        total: 0,
        pageNo: 1,
        pageSize: 10
      },
      roleIds: [], // 选中的角色id
      editData: null // 编辑数据
    }
  },
  created () {
    this.getRoleList()
  },
  methods: {
    async getRoleList () {
      const res = await this.$api.getRoleList(
        Object.assign(this.searchForm, {
          pageNo: this.pager.pageNo,
          pageSize: this.pager.pageSize
        })
      )
      if (res !== 'fail&&error') {
        this.tableData = res.data.map(item => {
          item.memo = item.memo || '-'
          return item
        })
        this.pager.total = res.total
      }
    },
    currentChange (pageNo) {
      this.pager.pageNo = pageNo
      this.getRoleList()
    },
    // 多选框
    handleSelectionChange (arr) {
      this.roleIds = arr.map(item => item.roleId)
    },
    // 删除
    handleDelete (roleIds = []) {
      if (!Array.isArray(roleIds) || roleIds.length === 0) {
        this.$confirm('请选择要删除的角色', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'error'
        }).then(() => {
        }).catch(() => {
        })
        return
      }

      this.$confirm('您确认要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(async () => {
        const res = await this.$api.deleteRole({
          roleIds: roleIds
        })
        if (res !== 'fail&&error') {
          this.getRoleList()
          this.$confirm('删除成功', '提示', {
            confirmButtonText: '确定',
            type: 'success'
          })
            .then(() => {})
            .catch(() => {})
        } else {
          this.getRoleList()
          // this.$confirm('删除失败', '提示', {
          //   confirmButtonText: '确定',
          //   type: 'error'
          // })
          //   .then(() => {})
          //   .catch(() => {})
        }
      })
        .catch(() => {})
    },

    async handleEdit (roleId) {
      const res = await this.$api.getRoleDetail({
        roleId
      })
      if (res !== 'fail&&error') {
        this.dialogName = 'add'
        this.editData = res
      }
    },
    hideDialog (bool) {
      this.dialogName = ''
      this.editData = null
      if (bool) {
        this.getRoleList()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.role-page {
  .search-box {
    border: .5px solid #ebeef5;
    padding: 0.66rem 1.2rem 0.12rem;
    .el-form-item {
      margin-bottom: 0.66rem;
    }
  }
  .pager {
    text-align: right;
    padding: 0.66rem 0 0;
  }

  /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
  ::-webkit-scrollbar {
    height: 12px;
    cursor: pointer;
    background-color: #6b6b6b5e;
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: rgba(88, 87, 87, 0.219);
  }
}
</style>
